// 申请处理弹窗
// sle
<template>
  <div id="PostDutyEditView">
    <a-modal
      v-model:visible="visible"
      :maskClosable="false"
      :title="title"
      ok-text="保存"
      cancel-text="取消"
      @ok="editSubit"
      @cancel="closeView"
    >
      <a-spin :spinning="spinning">
        <a-form
          :model="obj"
          :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 14 }"
          @finish="editSubit"
        >
          <a-form-item label="拒绝原因：" v-if="!result">
            <a-input v-model:value="obj.remark" :maxlength="50" />
          </a-form-item>
          <a-radio-group
            v-if="result"
            class="flex-column"
            v-model:value="obj.isEmployee"
          >
            <a-radio :value="true"> 内部联系人 </a-radio>
            <a-form-item
              label="选择部门"
              class="select"
              :required="obj.isEmployee"
            >
              <a-select
                v-model:value="departmentId"
                placeholder="请选择部门"
                :disabled="!obj.isEmployee"
              >
                <a-select-option
                  v-for="r in departmentList"
                  :key="r.linkKey"
                  :value="r.linkKey"
                  >{{ r.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item
              label="选择岗位"
              class="select"
              :required="obj.isEmployee"
            >
              <a-select
                mode="multiple"
                v-model:value="postSelectRows"
                :disabled="!obj.isEmployee"
                placeholder="请选择岗位"
              >
                <a-select-option
                  v-for="r in postList"
                  :key="r.linkKey"
                  :value="r.linkKey"
                  >{{ r.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-radio :value="false">
              外部联系人
              <a-form-item
                label="选择类型"
                class="select"
                :required="!obj.isEmployee"
              >
                <a-select
                  v-model:value="externalGenreId"
                  placeholder="请选择类型"
                  :disabled="obj.isEmployee"
                >
                  <a-select-option
                    v-for="r in externalGenreList"
                    :key="r.linkKey"
                    :value="r.linkKey"
                    >{{ r.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-radio>
          </a-radio-group>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { isEmpty } from '@/assets/common.js'

export default defineComponent({
  emits: ['getApplyJoinList'],
  props: {
    departmentList: {
      type: Array,
    },
    externalGenreList: {
      type: Array,
    },
    postList: {
      type: Array,
    },
  },
  data () {
    return {
      spinning: false, // 是否加载
      visible: false, // 弹窗是否显示
      result: false, // 处理方式
      title: '', // 弹窗标题
      departmentId: null,
      externalGenreId: null,
      postSelectRows: [], // 选择的岗位
      obj: {
      },
    }
  },
  methods: {
    // 拒绝按钮
    rejectClick (e) {
      this.visible = true
      this.title = '拒绝申请'
      this.result = false
      e.AuditResult = false
      e.remark = ''
      this.obj = e
    },
    // 同意按钮
    agreeClick (e) {
      this.visible = true
      this.title = '同意申请'
      this.result = true
      e.AuditResult = true
      e.isEmployee = true
      e.remark = '申请通过'
      this.obj = e
    },
    // 关闭窗口
    closeView () {
      this.visible = false
      this.title = ''
      this.result = false
      this.obj = {}
      this.departmentId = null
      this.postSelectRows = []
      this.externalGenreId = null
    },
    // 提交按钮
    editSubit () {
      const obj = this.obj
      if (!obj.result && this.title !== '拒绝申请') {
        if (obj.isEmployee) {
          if (isEmpty(this.departmentId) || this.postSelectRows.length === 0) {
            this.$message.error('请选择部门和岗位')
            return
          } else {
            obj.branchId = this.departmentId
            obj.postList = this.postSelectRows
          }
        } else {
          if (isEmpty(this.externalGenreId)) {
            this.$message.error('请选择类型')
            return
          } else {
            obj.branchId = this.externalGenreId
          }
        }
      }
      // obj.branchId = obj.isEmployee ? this.departmentId : this.externalGenreId
      // if (isEmpty(obj.branchId) && obj.AuditResult) {
      //   this.$message.error('请选择部门和岗位或是外部联系人')
      //   return
      // }
      this.spinning = true
      api
        .put('/api/app/ent-apply-join/ent-apply-join', obj)
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.closeView()
          this.$emit('getApplyJoinList')
          this.spinning = false
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.select {
  margin-top: 15px;
}
</style>
