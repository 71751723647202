// 下拉数据请求方法
// 通讯录部分
import api from '@/api/API'

// 获取部门列表下拉
export const getDepartmentSelectList = () => {
  return api.request({
    method: 'get',
    url: '/api/app/department/department-select-list',
  })
}

// 获取外部联系人下拉
export const getExternalGenreSelectList = () => {
  return api.request({
    method: 'get',
    url: '/api/app/external-genre/external-genre-select-list',
  })
}

// 获取岗位下拉
export const getPostSelectList = () => {
  return api.request({
    method: 'get',
    url: '/api/app/employee-post/post-select-list',
  })
}
