// 申请记录
// sle
<template>
  <div class="main flex-column" :style="{ minHeight: minHeight + 'px' }">
    <page-head title="申请记录" />
    <div class="list">
      <a-spin :spinning="spinning">
        <a-list item-layout="vertical" :data-source="list">
          <template #renderItem="{ item }">
            <a-list-item class="item flex-column justify-between">
              <div class="top flex-row justify-between">
                <div class="applican ellipsis" :title="item.applican">申请人：{{ item.applican }}</div>
                <div class="phone">手机号：{{ item.phone }}</div>
                <div class="creationTimeS flex-rowReverse">
                  申请日期：{{ item.creationTimeS }}
                </div>
              </div>
              <div v-if="item.audited" class="bottom flex-row justify-between">
                <div class="auditor ellipsis" :title="item.auditor">处理人：{{ item.auditor }}</div>
                <div class="remark" v-if="item.remark !== null">
                  备注：{{ item.remark }}
                </div>
                <div class="auditDateS flex-rowReverse">
                  处理日期：{{ item.auditDateS }}
                </div>
              </div>
              <div v-if="!item.audited" class="button flex-rowReverse">
                <a-button
                  type="danger"
                  :disabled="!disabled"
                  :title="!disabled == true ? title : ''"
                  @click="rejectClick(item)"
                  >拒绝</a-button
                >
                <a-button
                  class="agree"
                  type="primary"
                  :disabled="!disabled"
                  :title="!disabled == true ? title : ''"
                  @click="agreeClick(item)"
                >
                  同意
                </a-button>
              </div>
            </a-list-item>
          </template>
        </a-list>
      </a-spin>
      <div class="flex-rowReverse">
        <a-pagination
          v-model:current="pagination.current"
          v-model:pageSize="pagination.pageSize"
          hideOnSinglePage
          :total="pagination.total"
          @change="getApplyJoinList"
          class="pagination"
        />
      </div>
    </div>
    <ApplyDisposeView
      ref="disposeView"
      :departmentList="departmentList"
      :externalGenreList="externalGenreList"
      :postList="postList"
      @getApplyJoinList="getApplyJoinList"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../components/sameVariable'
import PageHead from '@/components/PageHead'
import { getDepartmentSelectList, getExternalGenreSelectList, getPostSelectList } from '@/api/SelectList/AddressBook'
import ApplyDisposeView from './ApplyDisposeView.vue'
import { addressBookJurisdiction } from '@/assets/common.js'

export default defineComponent({
  components: {
    'page-head': PageHead,
    ApplyDisposeView,
  },
  data () {
    return {
      title: '暂无权限',
      disabled: false,
      spinning: false, // 是否加载
      list: [], // table数据
      externalGenreList: [], // 外部联系人类型
      departmentList: [], // 部门
      postList: [], // 岗位
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      }, // 分页部分
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    this.getApplyJoinList()
    this.getDataSum()
    this.getJurisdiction()
    this.getPostSelectList()
  },
  // 方法
  methods: {
    getJurisdiction () {
      this.disabled = addressBookJurisdiction()
    },
    // 获取部门下拉
    getDepartmentList () {
      if (this.departmentList.length === 0) {
        getDepartmentSelectList()
          .then(res => {
            this.departmentList = res.data
          })
          .catch(err => {
            // this.$message.error('获取部门信息失败')
            console.log(err)
          })
      }
    },
    // 获取外部联系人下拉
    getExternalGenreSelectList () {
      if (this.externalGenreList.length === 0) {
        getExternalGenreSelectList()
          .then(res => {
            this.externalGenreList = res.data
          })
          .catch(err => {
            // this.$message.error('获取外部联系人类型失败')
            console.log(err)
          })
      }
    },
    // 获取岗位列表
    getPostSelectList () {
      getPostSelectList()
        .then(res => {
          this.postList = res.data
        })
        .catch(err => {
          // this.$message.error('获取岗位数据失败')
          console.log(err)
        })
    },
    // 获取table总数
    getDataSum () {
      api
        .get('/api/app/ent-apply-join/ent-apply-join-sum')
        .then(({ data }) => {
          this.pagination.total = data
        })
        .catch(err => {
          // this.$message.error('获取总数失败')
          console.log(err)
        })
    },
    // 获取table 数据
    getApplyJoinList () {
      this.spinning = true
      api
        .get('/api/app/ent-apply-join/paging-ent-apply-join-list', {
          params: {
            PageSize: this.pagination.pageSize,
            Pagination: this.pagination.current,
          },
        })
        .then(({ data }) => {
          this.spinning = false
          this.list = data
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('申请记录获取失败')
          console.log(err)
        })
    },
    // 拒绝按钮
    rejectClick (e) {
      e.auditResult = false
      this.$refs.disposeView.rejectClick(e)
    },
    // 同意按钮
    agreeClick (e) {
      this.getDepartmentList()
      this.getExternalGenreSelectList()
      e.auditResult = true
      this.$refs.disposeView.agreeClick(e)
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";

.main {
  background: #fff;
}

.list {
  padding: 20px;
  .item {
    height: 80px;
    .top {
      .applican {
        width: 23%;
      }
      .phone {
        width: 43%;
      }
      .creationTimeS {
        width: 33%;
      }
    }
    .bottom {
      .auditor {
        width: 23%;
      }
      .remark {
        width: 43%;
      }
      .auditDateS {
        width: 33%;
      }
    }
    .button {
      margin-top: 5px;
      .agree {
        margin-right: 20px;
      }
    }
  }
}

.pagination {
  min-width: 100px;
  margin-top: 20px;
}
</style>
